import React, { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Hero from '../../components/Hero'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import { useRelayEnvironment } from 'react-relay/hooks'
import { createOperationDescriptor, getRequest } from 'relay-runtime'
import { uploadableDocsQuery } from '../../components/File/helpers'
import ExternalContactShowHeroCard from './ExternalContactShowHeroCard'
import ExternalHomeContent from './ExternalHomeContent'
import ExternalRelatedItemList from '../ExternalItemList/ExternalRelatedItemList'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import DriverContactEdit from '../Contact/DriverContactEdit'
import { ShowPageRenderContext } from '../../viewTypes'

const ExternalContactShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <ExternalContactShowWithParams slug={slug} tab={tab} />
}

interface Params {
  slug: string
  tab: string
  withSkeleton?: boolean
  renderContext?: ShowPageRenderContext
}

export const ExternalContactShowWithParams: React.FC<Params> = ({ slug, tab, withSkeleton = false, renderContext = 'drawer' }) => {
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')
  const environment = useRelayEnvironment()

  useEffect(() => {
    // telling relay to retain these queries in store even when their components are not mounted (until this component unmounts)
    const uploadableDocsQueryDisposable = environment.retain(
      createOperationDescriptor(getRequest(uploadableDocsQuery), { entityType: 'contacts' })
    )

    return () => {
      uploadableDocsQueryDisposable.dispose()
    }
  }, [slug, environment])

  return (
    <EntityShowOrchestrator
      showPage={
        <>
          {/* <Hero backgroundColor='transparent'>
            <ExternalContactShowHeroCard slug={slug} />
          </Hero> */}
          <Hero backgroundColor='transparent'>
            {withSkeleton ? (
              <Suspense fallback={<ExternalHomeContent.Skeleton />}>
                <ExternalContactShowHeroCard slug={slug} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
              </Suspense>
            ) : (
              <ExternalContactShowHeroCard slug={slug} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
            )}
          </Hero>
          <NiceTabPanel forTab='home' activeTab={tab}>
            <Suspense fallback={<ExternalHomeContent.Skeleton />}>
              <ExternalHomeContent slug={slug} />
            </Suspense>
          </NiceTabPanel>
          <NiceTabPanel forTab='shipments' activeTab={tab}>
            <Suspense fallback={<ExternalRelatedItemList.Skeleton />}>
              <ExternalRelatedItemList type='shipments' contactSlug={slug} />
            </Suspense>
          </NiceTabPanel>
        </>
      }
      editPage={<DriverContactEdit slug={slug} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    ></EntityShowOrchestrator>
  )
}

export default ExternalContactShow
