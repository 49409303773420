/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ItemListHeader_data = {
    readonly sortKeys: ReadonlyArray<{
        readonly key: string;
        readonly title: string;
    }>;
    readonly filters: ReadonlyArray<{
        readonly key: string;
        readonly name: string | null;
        readonly values: ReadonlyArray<{
            readonly label: string;
            readonly displayLabel: string;
            readonly selected: boolean;
            readonly count: string;
        }> | null;
    }> | null;
    readonly totalCount: number | null;
    readonly downloadToken: string;
    readonly " $refType": "ItemListHeader_data";
};
export type ItemListHeader_data$data = ItemListHeader_data;
export type ItemListHeader_data$key = {
    readonly " $data"?: ItemListHeader_data$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemListHeader_data">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemListHeader_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SortKey",
      "kind": "LinkedField",
      "name": "sortKeys",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Filter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "selected",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadToken",
      "storageKey": null
    }
  ],
  "type": "ItemList",
  "abstractKey": null
};
})();
(node as any).hash = '61a95f24e00831a45872dbd71371db0b';
export default node;
