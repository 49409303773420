import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense } from 'react'
import EmailsCard from '../../components/Email/EmailsCard'
import MessagesCard from '../../components/Message/MessagesCard'
import FoldersCard from '../../components/File/FoldersCard'
import ShipmentFinanceMetricCard from '../../components/Finances/ShipmentFinanceMetricCard'
import LinkedContactsCard from '../../components/LinkedContactsCard'
import LiveRequest from '../../components/LiveRequest'
import LiveShare from '../../components/LiveShare'
import { AddNotes } from '../../components/Notes'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import ShipmentInfoCard from '../../components/ShipmentInfoCard'
import Timeline from '../../components/Timeline/Timeline'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import { ShipmentShowHomeContentQuery } from '../../__generated__/ShipmentShowHomeContentQuery.graphql'
import ShipmentCompanyCard from './ShipmentCompanyCard'
import VinDetailsCard from './VinDetailsCard'

interface Props {
  slug: string
}

const ShipmentShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const { shipmentShow: shipment } = useLazyLoadQueryWithSubscription<ShipmentShowHomeContentQuery>(
    shipmentHomeQuery,
    { slug: slug },
    { fetchPolicy: 'store-and-network', subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  return (
    <ResponsiveGrid
      type='show-layout'
      left={[
        <ShipmentInfoCard key='info-card' shipment={shipment} />,
        <VinDetailsCard key='vin-details' shipment={shipment} />,
        <ShipmentCompanyCard key='company' shipment={shipment} />,
        <FoldersCard
          key='documents'
          folderType='documents'
          folders={shipment.folders}
          title='Documents'
          entityType='shipments'
          entitySlug={shipment.slug}
        />,
        <ShipmentFinanceMetricCard key='finances' shipment={shipment} withTitle />,
      ]}
      middle={[
        <LinkedContactsCard
          key='contacts'
          draftContacts={shipment.draftContacts}
          linkedContactRoles={shipment.linkedContactRoles}
          entitySlug={shipment.slug}
          entityType='shipments'
        />,
        <EmailsCard key='emails' entityType='shipments' entitySlug={shipment.slug} emails={shipment.emails} />,
        <MessagesCard key='messages' entityType='shipments' entitySlug={shipment.slug} messages={shipment.messages} />,
        <FoldersCard
          key='photos'
          folderType='photos'
          folders={shipment.folders}
          title='Photos'
          entityType='shipments'
          entitySlug={shipment.slug}
        />,
        <LiveShare key='live-shares' entitySlug={slug} variant='compact' liveShares={shipment.liveShares} />,
        <LiveRequest key='live-requests' liveRequests={shipment.liveRequests} entitySlug={slug} entityType='shipments' />,
      ]}
      right={[
        <Suspense key='notes' fallback={<AddNotes.Skeleton />}>
          <AddNotes entitySlug={slug} entityType='shipments' />
        </Suspense>,
        <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
          <Timeline slug={slug} type='shipments' />
        </Suspense>,
      ]}
    />
  )
}

export const shipmentHomeQuery = graphql`
  query ShipmentShowHomeContentQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      slug
      ...ShipmentFinanceCard_shipment
      ...ShipmentInfoCard_shipment
      ...VinDetailsCard_shipment
      ...ShipmentCompanyCard_shipment
      ...ShipmentFinanceMetricCard_shipment
      ...UploadDocumentsWizard_shipment
      draftContacts {
        ...LinkedContactsCard_draftContacts
      }
      linkedContactRoles {
        ...LinkedContactsCard_linkedContactRoles
      }
      liveShares {
        ...LiveShare_liveShares
      }
      liveRequests {
        ...LiveRequest_liveRequests
      }
      emails {
        ...EmailsCard_emails
      }
      messages {
        ...MessagesCard_messages
      }
      folders {
        ...FoldersCard_folders
        ...UploadDocumentsWizard_folders
      }
    }
  }
`

ShipmentShowHomeContent.Skeleton = () => <>Loading...</>

export default ShipmentShowHomeContent
