import React, { unstable_useTransition, useEffect, useState } from 'react'
import { Autocomplete, TextField, Checkbox, ListItemText, Popper } from '@mui/material'
import { parse, stringify } from 'qs'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

interface Option {
  label: string
  displayLabel: string
  count?: number
}

interface Props {
  name: string
  filterKey: string
  data: Option[]
}

const AutocompleteContainer = styled.div`
  padding: 1px 5px;
`
const StyledTagContainer = styled.div`
  height: 0px;
`
const StyledOption = styled.li`
  display: flex;
  align-items: center;
  width: 225px;
`
const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    width: 90px;
    height: 50px;
  }
`

const SearchableMultiSelectFilter: React.FC<Props> = ({ filterKey, name, data }) => {
  const CustomPopper = (props: React.ComponentProps<typeof Popper>) => {
    return <Popper {...props} style={{ zIndex: 1300 }} />
  }

  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const [startTransition] = unstable_useTransition()
  const navigate = useNavigate()

  const [selectedValues, setSelectedValues] = useState<Option[]>([])
  const [openDropdown, setOpenDropdown] = useState(false)

  const options = data.map((item) => ({
    label: item.label,
    displayLabel: item.displayLabel,
  }))

  useEffect(() => {
    const tempVars = { ...queryVariables }
    tempVars[filterKey] = selectedValues.map((value) => value.label)
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }, [selectedValues])

  const handleCheckboxChange = (option: Option) => {
    setSelectedValues((prevSelected) => {
      if (prevSelected.some((selected) => selected.label === option.label)) {
        return prevSelected.filter((selected) => selected.label !== option.label)
      } else {
        return [...prevSelected, option]
      }
    })
  }

  const handleClear = () => {
    setSelectedValues([])
  }
  return (
    <AutocompleteContainer>
      <Autocomplete
        multiple
        size='small'
        options={options}
        getOptionLabel={(option) => option.displayLabel}
        value={selectedValues}
        open={openDropdown}
        onOpen={() => setOpenDropdown(true)}
        onClose={() => setOpenDropdown(false)}
        onChange={(event, newValue) => {
          if (newValue.length === 0) {
            handleClear()
          }
        }}
        PopperComponent={CustomPopper}
        renderInput={(params) => <StyledTextField {...params} variant='outlined' label={name} />}
        renderTags={(value) => (
          <StyledTagContainer>
            <span>{`(${value.length})`}</span>
          </StyledTagContainer>
        )}
        renderOption={(props, option) => (
          <StyledOption {...props}>
            <Checkbox
              checked={selectedValues.some((selected) => selected.label === option.label)}
              onClick={() => handleCheckboxChange(option)}
            />
            <ListItemText primary={option.displayLabel} />
          </StyledOption>
        )}
      />
    </AutocompleteContainer>
  )
}

export default SearchableMultiSelectFilter
