/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ExternalItemListQueryVariables = {
    searchQuery?: string | null;
    type?: ExternalListItemTypeEnum | null;
    filters?: Array<FilterInput> | null;
    includeArchived?: boolean | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
    voyageEtaStart?: string | null;
    voyageEtaEnd?: string | null;
    dischargeDateStart?: string | null;
    dischargeDateEnd?: string | null;
    count?: number | null;
    cursor?: string | null;
    showFilters?: boolean | null;
};
export type ExternalItemListQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ExternalItemList_data">;
};
export type ExternalItemListQuery = {
    readonly response: ExternalItemListQueryResponse;
    readonly variables: ExternalItemListQueryVariables;
};



/*
query ExternalItemListQuery(
  $searchQuery: String
  $type: ExternalListItemTypeEnum
  $filters: [FilterInput!]
  $includeArchived: Boolean
  $sortKey: String
  $page: Int
  $perPage: Int
  $voyageEtaStart: Date
  $voyageEtaEnd: Date
  $dischargeDateStart: Date
  $dischargeDateEnd: Date
  $count: Int
  $cursor: String
  $showFilters: Boolean
) {
  ...ExternalItemList_data
}

fragment Badge_data on Badge {
  type
}

fragment ExternalItemList_data on ApplicationQuery {
  externalItemList(searchQuery: $searchQuery, type: $type, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd, showFilters: $showFilters) {
    items(first: $count, after: $cursor) {
      edges {
        node {
          slug
          ...ExternalListPageItem_listItem
          id
          __typename
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}

fragment ExternalListPageItem_listItem on ExternalListItem {
  slug
  title
  isArchived
  image {
    ...ItemImage_data
  }
  path
  stages {
    key
    status
    label
    value
  }
  badges {
    key
    ...Badge_data
  }
  lines {
    key
    ...SemanticLine_data
  }
  keyFields {
    key
    ...KeyField_data
  }
  stickyNoteContentByExternal
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateEnd"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateStart"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeArchived"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showFilters"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaEnd"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaStart"
},
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v17 = [
  (v15/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalItemListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ExternalItemList_data"
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v11/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v10/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v13/*: any*/),
      (v12/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalItemListQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "dischargeDateEnd",
            "variableName": "dischargeDateEnd"
          },
          {
            "kind": "Variable",
            "name": "dischargeDateStart",
            "variableName": "dischargeDateStart"
          },
          {
            "kind": "Variable",
            "name": "filters",
            "variableName": "filters"
          },
          {
            "kind": "Variable",
            "name": "includeArchived",
            "variableName": "includeArchived"
          },
          {
            "kind": "Variable",
            "name": "page",
            "variableName": "page"
          },
          {
            "kind": "Variable",
            "name": "perPage",
            "variableName": "perPage"
          },
          {
            "kind": "Variable",
            "name": "searchQuery",
            "variableName": "searchQuery"
          },
          {
            "kind": "Variable",
            "name": "showFilters",
            "variableName": "showFilters"
          },
          {
            "kind": "Variable",
            "name": "sortKey",
            "variableName": "sortKey"
          },
          {
            "kind": "Variable",
            "name": "type",
            "variableName": "type"
          },
          {
            "kind": "Variable",
            "name": "voyageEtaEnd",
            "variableName": "voyageEtaEnd"
          },
          {
            "kind": "Variable",
            "name": "voyageEtaStart",
            "variableName": "voyageEtaStart"
          }
        ],
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "ExternalListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalListItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalListItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isArchived",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "alt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "highlight",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shape",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "overlay",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "overlayIcon",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "path",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stage",
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Badge",
                        "kind": "LinkedField",
                        "name": "badges",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticLine",
                        "kind": "LinkedField",
                        "name": "lines",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "parts",
                            "plural": true,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeyField",
                        "kind": "LinkedField",
                        "name": "keyFields",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "value",
                            "plural": true,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stickyNoteContentByExternal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v14/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "ItemList_itemList_items",
            "kind": "LinkedHandle",
            "name": "items"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbde51860fc83e2036552799f5bc56cd",
    "id": null,
    "metadata": {},
    "name": "ExternalItemListQuery",
    "operationKind": "query",
    "text": "query ExternalItemListQuery(\n  $searchQuery: String\n  $type: ExternalListItemTypeEnum\n  $filters: [FilterInput!]\n  $includeArchived: Boolean\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n  $voyageEtaStart: Date\n  $voyageEtaEnd: Date\n  $dischargeDateStart: Date\n  $dischargeDateEnd: Date\n  $count: Int\n  $cursor: String\n  $showFilters: Boolean\n) {\n  ...ExternalItemList_data\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ExternalItemList_data on ApplicationQuery {\n  externalItemList(searchQuery: $searchQuery, type: $type, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd, showFilters: $showFilters) {\n    items(first: $count, after: $cursor) {\n      edges {\n        node {\n          slug\n          ...ExternalListPageItem_listItem\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}\n\nfragment ExternalListPageItem_listItem on ExternalListItem {\n  slug\n  title\n  isArchived\n  image {\n    ...ItemImage_data\n  }\n  path\n  stages {\n    key\n    status\n    label\n    value\n  }\n  badges {\n    key\n    ...Badge_data\n  }\n  lines {\n    key\n    ...SemanticLine_data\n  }\n  keyFields {\n    key\n    ...KeyField_data\n  }\n  stickyNoteContentByExternal\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '35313bf6e60d01c0ecd3835695194714';
export default node;
