import { DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import OdysseyDialog from './OdysseyDialog'
import React, { Suspense, useState } from 'react'
import styled from 'styled-components'
import { ExternalContainerTickets, ExternalShipmentTickets, ExternalTickets } from '../utils/ExternalTicketType'
import DraftContainerAdd from './DraftContainerAdd'
import DraftShipmentAdd from './DraftShipmentAdd'
import DriverContactAdd from './DriverContactAdd'

interface Props {
  entityType: string
  open: boolean
  onClose: () => void
}

const Root = styled.div`
  padding: 20px;
`
const AddTicketDialog: React.FC<Props> = (props) => {
  const defaultSelectedTicket =
    props.entityType === 'containers'
      ? 'request_to_create_a_new_container'
      : props.entityType === 'shipments'
      ? 'request_to_create_a_new_shipment'
      : props.entityType === 'external_contacts'
      ? 'request_to_create_a_new_driver'
      : ''

  const [selectedTicket, setSelectedTicket] = useState<string>(defaultSelectedTicket)

  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <DialogTitle>{props.entityType == 'external_contacts' ? 'Create a new driver' : 'Raise new ticket'}</DialogTitle>
      {props.entityType != 'external_contacts' && (
        <Root>
          <FormControl fullWidth>
            <InputLabel>Please select the ticket type.</InputLabel>
            <Select value={selectedTicket} onChange={(e) => setSelectedTicket(e.target.value as string)}>
              {props.entityType === 'tickets' &&
                ExternalTickets.map((ticket) => (
                  <MenuItem key={ticket.value} value={ticket.value}>
                    {ticket.label}
                  </MenuItem>
                ))}
              {props.entityType === 'shipments' &&
                ExternalShipmentTickets.map((ticket) => (
                  <MenuItem key={ticket.value} value={ticket.value}>
                    {ticket.label}
                  </MenuItem>
                ))}
              {props.entityType === 'containers' &&
                ExternalContainerTickets.map((ticket) => (
                  <MenuItem key={ticket.value} value={ticket.value}>
                    {ticket.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Root>
      )}

      {selectedTicket === 'request_to_create_a_new_container' && (
        <Suspense fallback={<p>Laoding..</p>}>
          <DraftContainerAdd {...props} />
        </Suspense>
      )}
      {selectedTicket === 'request_to_create_a_new_shipment' && (
        <Suspense fallback={<p>Laoding..</p>}>
          <DraftShipmentAdd {...props} />
        </Suspense>
      )}
      {selectedTicket === 'request_to_create_a_new_driver' && (
        <Suspense fallback={<p>Laoding..</p>}>
          <DriverContactAdd {...props} />
        </Suspense>
      )}
    </OdysseyDialog>
  )
}

export default AddTicketDialog
