/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalShowItemTypeEnum = "contact" | "container" | "shipment" | "%future added value";
export type RegistrationStatus = "invited" | "not_invited" | "registered" | "%future added value";
export type ExternalContactShowHeroCardQueryVariables = {
    slug: string;
    itemType: ExternalShowItemTypeEnum;
};
export type ExternalContactShowHeroCardQueryResponse = {
    readonly externalItemShow: {
        readonly title: string;
        readonly slug: string;
        readonly keyFields: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
        }> | null;
        readonly image: {
            readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
        } | null;
        readonly lines: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
        }> | null;
        readonly badges: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
        }> | null;
    };
    readonly externalContactShow: {
        readonly slug: string;
        readonly canEdit: boolean;
        readonly registrationStatus: RegistrationStatus;
        readonly invitation: {
            readonly url: string;
            readonly sentAt: string;
        } | null;
        readonly user: {
            readonly createdAt: string;
        } | null;
        readonly revokeAccess: boolean;
        readonly externalInvitable: boolean;
    };
};
export type ExternalContactShowHeroCardQuery = {
    readonly response: ExternalContactShowHeroCardQueryResponse;
    readonly variables: ExternalContactShowHeroCardQueryVariables;
};



/*
query ExternalContactShowHeroCardQuery(
  $slug: String!
  $itemType: ExternalShowItemTypeEnum!
) {
  externalItemShow(slug: $slug, type: $itemType) {
    title
    slug
    keyFields {
      key
      ...KeyField_data
    }
    image {
      ...ItemImage_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    badges {
      key
      ...Badge_data
    }
    id
  }
  externalContactShow(slug: $slug) {
    slug
    canEdit
    registrationStatus
    invitation {
      url
      sentAt
      id
    }
    user {
      createdAt
    }
    revokeAccess
    externalInvitable
  }
}

fragment Badge_data on Badge {
  type
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "itemType"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v7 = [
  (v2/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationStatus",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revokeAccess",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalInvitable",
  "storageKey": null
},
v15 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalContactShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ExternalListItem",
        "kind": "LinkedField",
        "name": "externalItemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "KeyField_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ItemImage_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SemanticLine_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Badge_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ExternalContact",
        "kind": "LinkedField",
        "name": "externalContactShow",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Invitation",
            "kind": "LinkedField",
            "name": "invitation",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalContactShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ExternalListItem",
        "kind": "LinkedField",
        "name": "externalItemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": (v15/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shape",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlayIcon",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "parts",
                "plural": true,
                "selections": (v15/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ExternalContact",
        "kind": "LinkedField",
        "name": "externalContactShow",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Invitation",
            "kind": "LinkedField",
            "name": "invitation",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6474196ca47839266d6fe893e4182c68",
    "id": null,
    "metadata": {},
    "name": "ExternalContactShowHeroCardQuery",
    "operationKind": "query",
    "text": "query ExternalContactShowHeroCardQuery(\n  $slug: String!\n  $itemType: ExternalShowItemTypeEnum!\n) {\n  externalItemShow(slug: $slug, type: $itemType) {\n    title\n    slug\n    keyFields {\n      key\n      ...KeyField_data\n    }\n    image {\n      ...ItemImage_data\n    }\n    lines {\n      key\n      ...SemanticLine_data\n    }\n    badges {\n      key\n      ...Badge_data\n    }\n    id\n  }\n  externalContactShow(slug: $slug) {\n    slug\n    canEdit\n    registrationStatus\n    invitation {\n      url\n      sentAt\n      id\n    }\n    user {\n      createdAt\n    }\n    revokeAccess\n    externalInvitable\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '5f8a25063971373e813b22ee0dd4a5c9';
export default node;
