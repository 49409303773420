import { Card, IconButton, Link, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import Badge from '../../components/Badge'
import ItemImage from '../../components/ItemImage'
import KeyField from '../../components/KeyField'
import SemanticLine from '../../components/SemanticLine'
import { ContactHeader, HeroContainer, ProfileContainer, Title, TitleContainer } from '../../components/ShowPageItem'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import { media } from '../../theme'
import { ExternalContactShowHeroCardQuery } from '../../__generated__/ExternalContactShowHeroCardQuery.graphql'
import { ExternalContactShowHeroCardMutation } from '../../__generated__/ExternalContactShowHeroCardMutation.graphql'
import { TabContainer } from '../ExternalShipment/ExternalShipmentShowHeroCard'
import Hero from '../../components/Hero'
import TabsUI from '../../components/TabsUI/TabsUI'
import { tabs } from './tabs'
import { useNavigate, useParams } from 'react-router'
import Button from '../../components/Button'
import useNiceMutation from '../../mutations/useNiceMutation'
import LoadingDots from '../../components/LoadingDots'
import { useSnackbar } from 'notistack'
import RevokeAccess from '../../components/RevokeAccess'
import { Edit } from '@material-ui/icons'
import { ShowPageRenderContext } from '../../viewTypes'

interface Props {
  slug: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const NormalActions = styled(FlexContainer)`
  ${media.small`
    display: none;
  `}
`

export const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.large`
    grid-template-columns: repeat(auto-fill, 216px);
  `}

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`
export const DetailsCard = styled(Card)`
  width: 100%;
  padding: 8px 16px;
`

const ExternalContactShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onEdit }) => {
  const { externalItemShow: item, externalContactShow: contact } = useLazyLoadQueryWithSubscription<ExternalContactShowHeroCardQuery>(
    query,
    { slug: slug, itemType: 'contact' },
    { subscriptionOptions: { entityType: 'contacts', entitySlug: slug } }
  )
  const [commitSendInvite, sendInviteIsInFlight] = useNiceMutation<ExternalContactShowHeroCardMutation>(sendInviteMutation)

  const { enqueueSnackbar } = useSnackbar()
  const { workspace, tab } = useParams()
  const navigate = useNavigate()
  const value = tabs.findIndex((t) => t.value === tab)
  const inviteActions =
    contact.registrationStatus === 'not_invited'
      ? ['send_invitation']
      : contact.registrationStatus === 'invited'
      ? ['copy_invitation_url', 'resend_invitation', 'delete_invitation']
      : []
  const invitationActionCopy =
    contact.registrationStatus === 'invited'
      ? `Invitation sent ${contact?.invitation?.sentAt}`
      : contact.registrationStatus === 'registered'
      ? `Member since ${contact?.user?.createdAt}`
      : null

  const onTabChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    navigate(`/${workspace}/contact/${item.slug}/${tabs[newValue].value}`)
  }

  const handleInvitation = () => {
    if (sendInviteIsInFlight) return

    commitSendInvite({ variables: { input: { slug: contact.slug } } })
  }

  const copyInviteLink = () => {
    navigator.clipboard.writeText(contact?.invitation?.url || '')
    enqueueSnackbar('Link copied to clipboard')
  }

  return (
    <>
      <HeroContainer>
        <ProfileContainer>{item.image && <ItemImage data={item.image} />}</ProfileContainer>
        <ContactHeader>
          <TitleContainer>
            <Title variant='subtitle1' color='textPrimary'>
              {item.title}
              {item.badges?.map((badge) => (
                <Badge key={badge.key} data={badge} />
              ))}
            </Title>
          </TitleContainer>
          {item.lines?.map((line) => (
            <SemanticLine key={line.key} data={line} />
          ))}

          <DetailsCard variant='outlined'>
            {item.keyFields && (
              <KeyFieldsContainer>
                {item.keyFields.map((field) => (
                  <KeyField key={field.key} data={field} />
                ))}
              </KeyFieldsContainer>
            )}
          </DetailsCard>
        </ContactHeader>
      </HeroContainer>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
          <FlexContainer>
            <NormalActions>
              {invitationActionCopy && <Typography variant='body2'>{invitationActionCopy}</Typography>}
              {contact.externalInvitable &&
                inviteActions.map((action) => {
                  switch (action) {
                    case 'send_invitation':
                      return (
                        <Button key={action} variant='outlined' onClick={handleInvitation}>
                          {sendInviteIsInFlight ? <LoadingDots variant='primary' /> : 'Invite'}
                        </Button>
                      )
                    case 'copy_invitation_url':
                      return (
                        <IconButton key={action} onClick={copyInviteLink}>
                          <Link />
                        </IconButton>
                      )
                    case 'resend_invitation':
                      return (
                        <Button key={action} variant='outlined' onClick={handleInvitation}>
                          {sendInviteIsInFlight ? <LoadingDots variant='primary' /> : 'Resend'}
                        </Button>
                      )
                  }
                  return <></>
                })}
              {contact.canEdit && (
                <Button variant='contained' startIcon={<Edit />} onClick={() => onEdit()} disabled={!contact.canEdit}>
                  Edit
                </Button>
              )}
              {contact.registrationStatus === 'registered' && contact.externalInvitable && (
                <RevokeAccess username={contact.slug} revokeAccess={contact.revokeAccess}></RevokeAccess>
              )}
            </NormalActions>
          </FlexContainer>
        </TabContainer>
      </Hero>
    </>
  )
}

const query = graphql`
  query ExternalContactShowHeroCardQuery($slug: String!, $itemType: ExternalShowItemTypeEnum!) {
    externalItemShow(slug: $slug, type: $itemType) {
      title
      slug
      title
      keyFields {
        key
        ...KeyField_data
      }
      image {
        ...ItemImage_data
      }
      lines {
        key
        ...SemanticLine_data
      }
      badges {
        key
        ...Badge_data
      }
    }

    externalContactShow(slug: $slug) {
      slug
      canEdit
      registrationStatus
      invitation {
        url
        sentAt
      }
      user {
        createdAt
      }
      revokeAccess
      externalInvitable
    }
  }
`

const sendInviteMutation = graphql`
  mutation ExternalContactShowHeroCardMutation($input: infoSendInvitationInput!) {
    infoSendInvitation(input: $input) {
      contact {
        slug
      }
    }
  }
`

ExternalContactShowHeroCard.Skeleton = () => <>Loading...</>

export default ExternalContactShowHeroCard
