/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ShareContactQueryVariables = {
    contactSlug: string;
    searchQuery?: string | null;
    role?: string | null;
};
export type ShareContactQueryResponse = {
    readonly consigneeList: ReadonlyArray<{
        readonly slug: string;
        readonly name: string;
        readonly shared: boolean;
    }>;
};
export type ShareContactQuery = {
    readonly response: ShareContactQueryResponse;
    readonly variables: ShareContactQueryVariables;
};



/*
query ShareContactQuery(
  $contactSlug: String!
  $searchQuery: String
  $role: String
) {
  consigneeList(searchQuery: $searchQuery, role: $role) {
    slug
    name
    shared(contactSlug: $contactSlug)
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      },
      {
        "kind": "Variable",
        "name": "searchQuery",
        "variableName": "searchQuery"
      }
    ],
    "concreteType": "ConsigneeList",
    "kind": "LinkedField",
    "name": "consigneeList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "contactSlug",
            "variableName": "contactSlug"
          }
        ],
        "kind": "ScalarField",
        "name": "shared",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareContactQuery",
    "selections": (v3/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ShareContactQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a3adc091e397dba96dc1da91ab26f075",
    "id": null,
    "metadata": {},
    "name": "ShareContactQuery",
    "operationKind": "query",
    "text": "query ShareContactQuery(\n  $contactSlug: String!\n  $searchQuery: String\n  $role: String\n) {\n  consigneeList(searchQuery: $searchQuery, role: $role) {\n    slug\n    name\n    shared(contactSlug: $contactSlug)\n  }\n}\n"
  }
};
})();
(node as any).hash = '07677500db2b840450427a1fea98bc90';
export default node;
