/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { Checkbox as MuiCheckbox } from '@material-ui/core'
import DateRangePicker from 'rsuite/DateRangePicker'
import { isSmall } from '../theme'
import styled from 'styled-components'

interface Props {
  filterType: string
  heading: string
  handleDateChange(filterType: string, newDateRange: string[]): any
  dateRange: string[]
}

const StyledDateRangePicker = styled(DateRangePicker)`
  margin: 5px;
  width: ${(props) => (props.isSmall ? '100px' : '225px')};
`

const DateRangePickerFilter: React.FC<Props> = ({ filterType, handleDateChange, heading, dateRange }) => {
  return (
    <>
      <StyledDateRangePicker
        placeholder={heading}
        format='dd/MM/yyyy'
        value={dateRange}
        showOneCalendar={isSmall()}
        onChange={(e: any) => {
          handleDateChange(filterType, e)
        }}
        isSmall={isSmall()}
      />
      {false && <MuiCheckbox color='primary' checked={true} />}
    </>
  )
}

export default DateRangePickerFilter
