import React, { useContext } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { useLocation } from 'react-router'
import { graphql } from 'babel-plugin-relay/macro'
import useNiceMutation from '../mutations/useNiceMutation'
import { RemoveSharedContactMutation } from '../__generated__/RemoveSharedContactMutation.graphql'
import { useSnackbar } from 'notistack'
import LoadingDots from './LoadingDots'
import { RefetchContext } from '../pages/ItemList/RelatedItemList'

interface Props {
  sharedSlug: string
  sharedContactType: string
}

const RemoveContainer = styled.div`
  margin-left: auto;
  padding-right: 10px;
`

const RemoveSharedContact: React.FC<Props> = ({ sharedSlug, sharedContactType }) => {
  const refetch = useContext(RefetchContext)
  const location = useLocation()
  const parts = location.pathname.split('/')
  const index = parts.indexOf('contact')
  const slug = index !== -1 && parts[index + 1] ? parts[index + 1] : null
  const [commit, loading] = useNiceMutation<RemoveSharedContactMutation>(mutation)
  const { enqueueSnackbar } = useSnackbar()

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    commit({
      variables: {
        input: {
          sharedSlug: sharedSlug,
          slug: slug as string,
          sharedContactType: sharedContactType,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          enqueueSnackbar('Shared contact removed successfully', { variant: 'success' })
          refetch()
        }
      },
    })
  }
  return (
    <RemoveContainer>
      <Button onClick={handleRemove}>{loading ? <LoadingDots /> : 'Remove'}</Button>
    </RemoveContainer>
  )
}

const mutation = graphql`
  mutation RemoveSharedContactMutation($input: infoRemoveSharedContactInput!) {
    infoRemoveSharedContact(input: $input) {
      clientMutationId
    }
  }
`
export default RemoveSharedContact
