/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateDriverContactInput = {
    clientMutationId?: string | null;
    slug: string;
    contactType: string;
    format: string;
    salutation: string;
    imageSlug?: string | null;
    firstName: string;
    lastName: string;
    email?: string | null;
    secondaryEmails?: Array<string> | null;
    businessPhone?: string | null;
    company?: string | null;
    position?: string | null;
    country: string;
    state?: string | null;
    place?: string | null;
    postcode?: string | null;
    address?: string | null;
    language?: string | null;
    eori?: string | null;
    vat?: string | null;
    note?: string | null;
};
export type DriverContactEditMutationVariables = {
    input: infoUpdateDriverContactInput;
};
export type DriverContactEditMutationResponse = {
    readonly infoUpdateDriverContact: {
        readonly contact: {
            readonly slug: string;
        };
    } | null;
};
export type DriverContactEditMutation = {
    readonly response: DriverContactEditMutationResponse;
    readonly variables: DriverContactEditMutationVariables;
};



/*
mutation DriverContactEditMutation(
  $input: infoUpdateDriverContactInput!
) {
  infoUpdateDriverContact(input: $input) {
    contact {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DriverContactEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateDriverContactPayload",
        "kind": "LinkedField",
        "name": "infoUpdateDriverContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DriverContactEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateDriverContactPayload",
        "kind": "LinkedField",
        "name": "infoUpdateDriverContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff0bf34b69fff8724b5236e1466147a0",
    "id": null,
    "metadata": {},
    "name": "DriverContactEditMutation",
    "operationKind": "mutation",
    "text": "mutation DriverContactEditMutation(\n  $input: infoUpdateDriverContactInput!\n) {\n  infoUpdateDriverContact(input: $input) {\n    contact {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c6bc3db293153ef59fbc2f7d74dc4f62';
export default node;
