import { Avatar, Card, CardContent, Divider, Typography } from '@material-ui/core'
import React, { Suspense, useContext, useState } from 'react'
import styled from 'styled-components'
import LandingBG from '../../assets/public/landing.png'
import Button from '../../components/Button'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import Timeline from '../../components/Timeline/Timeline'
import SessionContext from '../../SessionContext'
import { isMobile, media } from '../../theme'
import { Workspaces } from './SwitchWorkspaceDialog'
import { ChangePasswordDialog } from './UpdatePasswordDialog'
import BarcodeTwoFactorAuth from './BarcodeTwoFactorAuth'
import ContactEdit from '../Contact/ContactEdit'
import { graphql } from 'babel-plugin-relay/macro'
import { ProfileQuery } from '../../__generated__/ProfileQuery.graphql'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import { GenerateTokenDialog } from './GenerateTokenDialog'

const UserAvatar = styled(Avatar)`
  width: 192px;
  height: 192px;
  border: 4px solid white;
  margin-right: 16px;
`

const Hero = styled.div`
  width: 100%;
  margin-bottom: 16px;
  z-index: 0;
  overflow: hidden;
  position: relative;
  background-image: url('${LandingBG}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
`

const HeroContent = styled.div`
  width: 80%;
  margin: auto;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  padding: 64px 16px 16px 16px;

  ${media.mobile`
    width:90%;
    display: flex;
  `}

  ${media.desktop`
    width: 78%;
  `}
`

const TitleContainer = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
`

const KeyFieldContainer = styled.div`
  width: 100%;
  margin: 2px 0;
`

const Label = styled(Typography)`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.hint};
`

const Value = styled(Typography)`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 500;
  box-sizing: content-box;
  line-height: 24px;
`

const Title = styled(Typography)`
  font-weight: bold;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-flow: wrap;
`
const ButtonDiv = styled.div`
  margin-bottom: 5px;
`
const Profile: React.FC = () => {
  const { user } = useContext(SessionContext)
  const [passwordDialog, setPasswordDialog] = useState<boolean>(false)
  const [tokenDialog, setTokenDialog] = useState<boolean>(false)
  const [authDialog, setAuthDialog] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')
  const { sessionMe: data } = useLazyLoadQueryWithSubscription<ProfileQuery>(
    query,
    { slug: user?.username },
    { subscriptionOptions: { entityType: 'contacts', entitySlug: user?.username as string } }
  )
  return (
    <EntityShowOrchestrator
      showPage={
        <>
          {passwordDialog && <ChangePasswordDialog open={passwordDialog} onClose={() => setPasswordDialog(false)} />}
          {tokenDialog && <GenerateTokenDialog slug={user?.contact?.slug} open={tokenDialog} onClose={() => setTokenDialog(false)} />}
          {authDialog && (
            <BarcodeTwoFactorAuth
              open={authDialog}
              onClose={() => setAuthDialog(false)}
              otpRequiredForLogin={data?.otpRequiredForLogin as boolean}
              barcodeValue={data?.barcodeValue as string}
            />
          )}
          <Hero>
            <HeroContent>
              {!isMobile() && <UserAvatar src={user?.contact?.profileUrl || ''} />}
              <TitleContainer>
                <div>
                  <Title variant='h5'>{user?.contact?.name}</Title>
                  <Typography variant='body1'>{user?.contact?.displayRoles.map((role) => role.name).join(', ')}</Typography>
                </div>
                <ButtonContainer>
                  <ButtonDiv>
                    <Button variant='outlined' style={{ backgroundColor: 'white' }} onClick={() => setAuthDialog(true)}>
                      {data?.otpRequiredForLogin ? 'Disable' : 'Enable'} Two factor authentication
                    </Button>
                  </ButtonDiv>
                  <ButtonDiv>
                    <Button variant='outlined' style={{ backgroundColor: 'white' }} onClick={() => setPasswordDialog(true)}>
                      Change Password
                    </Button>
                  </ButtonDiv>
                  <ButtonDiv>
                    <Button variant='outlined' style={{ backgroundColor: 'white' }} onClick={() => setTokenDialog(true)}>
                      Generate API Token
                    </Button>
                  </ButtonDiv>
                  <ButtonDiv>
                    {user?.contact?.contactType === 'internal' && (
                      <Button variant='contained' onClick={() => setViewMode('edit')}>
                        Edit
                      </Button>
                    )}
                  </ButtonDiv>
                </ButtonContainer>
              </TitleContainer>
            </HeroContent>
          </Hero>
          <ResponsiveGrid
            type='show-layout'
            highlight={[
              <Card key='details' elevation={0} variant='outlined'>
                <div style={{ padding: '16px' }}>
                  <Typography variant='h6'>Details</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Profile details
                  </Typography>
                </div>
                <Divider variant='fullWidth' component='hr' />
                <CardContent>
                  <KeyField label='Username' value={user?.username || ''} />
                  <KeyField label='Name' value={user?.contact?.name || ''} />
                  <KeyField label='Email' value={user?.contact?.email || ''} />
                  <KeyField label='Roles' value={user?.contact?.displayRoles.map((role) => role.name).join(', ') || ''} />
                  <KeyField label='Position' value={user?.contact?.position || ''} />
                </CardContent>
              </Card>,
            ]}
            right={[
              <>
                {user?.contact?.contactType === 'internal' && (
                  <>
                    <Card key='workspace' elevation={0} variant='outlined'>
                      <div style={{ padding: '16px' }}>
                        <Typography variant='h6'>Workpaces</Typography>
                        <Typography variant='subtitle2' color='textSecondary'>
                          All workspaces that you have access to
                        </Typography>
                      </div>
                      <Divider variant='fullWidth' component='hr' />
                      <CardContent>
                        <Workspaces />
                      </CardContent>
                    </Card>
                    <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
                      {user?.contact?.contactType === 'internal' && <Timeline handle={user?.username} type='all' external />}
                    </Suspense>
                  </>
                )}
              </>,
            ]}
          />
        </>
      }
      editPage={<ContactEdit slug={`${user?.username}`} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    />
  )
}

interface KeyFieldProps {
  label: string
  value: string
}

const KeyField: React.FC<KeyFieldProps> = ({ label, value }) => {
  return (
    <KeyFieldContainer>
      <Label variant='caption' color='textSecondary'>
        {label}
      </Label>
      <Value variant='body1'>{value}</Value>
    </KeyFieldContainer>
  )
}

const query = graphql`
  query ProfileQuery {
    sessionMe {
      otpRequiredForLogin
      barcodeValue
    }
  }
`
export default Profile
