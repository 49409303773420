import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState, useEffect } from 'react'
import { useFragment } from 'react-relay/hooks'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { isLarge, isMedium, isSmall, media } from '../theme'
import { ViewMode } from '../viewTypes'
import { ListPageItem_listItem$key, StageStatusEnum } from '../__generated__/ListPageItem_listItem.graphql'
import Action from './Action'
import Badge from './Badge'
import ItemImage from './ItemImage'
import KeyField from './KeyField'
import LinkSet from './LinkSet/LinkSet'
import SemanticLine from './SemanticLine'
import SemanticString from './SemanticString'
import StickyNote from './StickyNote'
import TextHighlighter, { HighlightT } from './TextHighlighter'
import ArchivedBanner from './ArchivedBanner'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface Props {
  listItem: ListPageItem_listItem$key
  viewMode: ViewMode
  highlights?: HighlightT[]
  expandAll: boolean
}

interface ViewProps {
  view?: ViewMode
  isSmall?: boolean
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const Root = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  position: relative;
  overflow: hidden;
  flex-grow: 1;
`

const Topbar = styled(FlexContainer)`
  flex-flow: wrap;
  ${media.small`
    justify-content: space-between;
  `}
`

interface StepProps {
  status: StageStatusEnum
}

const Step = styled.div<StepProps>`
  width: max-content;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
  color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
  position: relative;
  height: 16px;
  padding: 12px 8px;
  margin-right: 16px;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    display: block;
    width: 0;
    height: 0;
    color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
    border-top: 12px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
    border-bottom: 12px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
    border-left: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -8px;
    display: block;
    width: 0;
    height: 0;
    color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 8px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
  }
`

const Stages = styled(FlexContainer)`
  margin-right: 16px;

  ${Step}:first-child {
    margin-left: 0;
    &:before {
      content: none;
    }
  }
`

const Status = styled.div`
  padding: 12px 8px;
  display: flex;
  align-items: center;
  background-color: #e4e4e4;
  position: relative;
  height: 16px;

  &:before {
    content: '';
    position: absolute;
    left: -16px;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 24px solid #e4e4e4;
    border-left: 16px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -16px;
    display: block;
    width: 0;
    height: 0;
    border-top: 24px solid #e4e4e4;
    border-right: 16px solid transparent;
  }
`

const StatusContainer = styled(FlexContainer)`
  ${Status}:first-child {
    margin-left: 0;
    &:before {
      content: none;
    }
  }

  /* ${Status}:last-child {
    margin-right: 0;
    &:after {
      content: none;
    }
  } */
`

const RootContainer = styled(FlexContainer)<ViewProps>`
  padding: ${(props) => (props.isSmall ? '1px 10px' : '16px')};
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  transition-duration: 300ms;

  ${(props) =>
    props.view === 'grid'
      ? `
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    flex-direction: row;
    align-items: flex-start;
  `
      : ''}
`

const Content = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

const TitleRow = styled(FlexContainer)<ViewProps>`
  width: 100%;
  justify-content: ${(props) => (props.view === 'grid' ? 'center' : 'space-between')};
`

const TitleContainer = styled(FlexContainer)<ViewProps>`
  align-items: flex-start;
  width: ${(props) => (props.view === 'grid' ? 'auto' : '100%')};
  margin-right: 8px;
`

const TitleText = styled(Typography)<ViewProps>`
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

const TitleLink = styled(Link)`
  margin-right: 8px;
  transition-duration: 200ms;
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: unset;

  &:hover h6 {
    color: #0085ff;
  }
`

const UnstyledLink = styled(Link)`
  text-decoration: unset;
  color: inherit;
`

const KeyFieldsContainer = styled.div<ViewProps>`
  margin: ${(props) => (props.isSmall ? '0px' : '4px 0 16px 0')};
  display: grid;
  grid-template-columns: ${(props) => (props.view === 'grid' ? 'repeat(auto-fill, 48%)' : 'repeat(auto-fill, 180px)')};
  grid-gap: 8px;
  width: 100%;

  ${(props) => media.large`
    grid-template-columns: ${props.view === 'grid' ? 'repeat(auto-fill, 48%)' : 'repeat(auto-fill, 214px)'};
  `}
`

const LinkSetContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
`

const ListPageItem: React.FC<Props> = ({ listItem, viewMode, highlights, expandAll }) => {
  const [expand, setExpand] = useState(true)
  useEffect(() => {
    setExpand(expandAll)
  }, [expandAll])
  const item = useFragment(listItemFragment, listItem)
  const maxFields = viewMode === 'grid' ? 4 : isMedium() ? 9 : 15

  return (
    <Root style={viewMode === 'grid' ? { margin: '10px', width: '380px' } : {}}>
      <ArchivedBanner isArchived={item.isArchived} variant='small' />
      <Topbar>
        {item.stages && item.stages.length > 0 && (
          <Stages>
            {item.stages.map((stage, idx) => (
              <Step key={stage.key} status={stage.status}>
                {stage.status === 'completed' || stage.status === 'incomplete' ? idx + 1 : stage.label}
              </Step>
            ))}
          </Stages>
        )}
        {item.statuses && item.statuses.length > 0 && (
          <StatusContainer>
            {item.statuses.map((status) => (
              <>
                <Status key={status.key}>
                  <SemanticString data={status.value} />
                </Status>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {status.customsAgent && <Status key={status.key}>{status.customsAgent}</Status>}
              </>
            ))}
          </StatusContainer>
        )}
      </Topbar>
      <RootContainer view={viewMode} isSmall={isSmall()}>
        {item.image && viewMode !== 'grid' && (
          <UnstyledLink to={`${item.path}/home` || '#'}>
            <ItemImage data={item.image} variant={viewMode === 'mini-list' || !(expand || expandAll) ? 'small' : null} />
          </UnstyledLink>
        )}

        <Content>
          <FlexContainer>
            {item.image && viewMode === 'grid' && (
              <UnstyledLink to={`${item.path}/home` || '#'}>
                <ItemImage variant='small' data={item.image} />
              </UnstyledLink>
            )}
            <TitleRow view={viewMode} title={item.title}>
              <TitleContainer view={viewMode}>
                <TitleLink to={`${item.path}/home` || '#'}>
                  <TitleText variant='subtitle1' color='textPrimary'>
                    <TextHighlighter highlights={highlights} textToHighlight={item.title} />
                  </TitleText>
                  {item.lines?.map((line) => (
                    <SemanticLine key={line.key} data={line} highlights={highlights} />
                  ))}
                </TitleLink>
                {item.badges?.map((badge) => (
                  <span key={badge.key} style={{ marginTop: '3px' }}>
                    <Badge key={badge.key} data={badge} />
                  </span>
                ))}
                {item.stickyNoteContent && isLarge() && viewMode !== 'grid' ? (
                  <StickyNote note={item.stickyNoteContent} type='internal' />
                ) : (
                  <></>
                )}
                &nbsp;&nbsp;
                {item.stickyNoteContentByExternal && isLarge() && viewMode !== 'grid' ? (
                  <StickyNote note={item.stickyNoteContentByExternal} type='external' />
                ) : (
                  <></>
                )}
              </TitleContainer>
              <div>{!isSmall() && item.actions?.map((action) => <Action key={action.key} data={action} itemSlug={item.slug} />)}</div>
              <Tooltip title={expand || expandAll ? 'Show less' : 'Show more'} placement='top' interactive>
                <IconButton onClick={() => setExpand(!expand)}>{expand || expandAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
              </Tooltip>
            </TitleRow>
          </FlexContainer>
          {item.stickyNoteContent && !(isLarge() && viewMode !== 'grid') ? <StickyNote note={item.stickyNoteContent} /> : <></>}
          {item.keyFields && (
            <KeyFieldsContainer view={viewMode} isSmall={isSmall()}>
              {item.keyFields?.slice(0, 4)?.map((field) => (
                <KeyField key={field.key} data={field} highlights={highlights} />
              ))}
            </KeyFieldsContainer>
          )}
          {item.keyFields && (expand || expandAll) && (
            <KeyFieldsContainer view={viewMode} isSmall={isSmall()}>
              {item.keyFields?.slice(4, maxFields)?.map((field) => (
                <KeyField key={field.key} data={field} highlights={highlights} />
              ))}
            </KeyFieldsContainer>
          )}
          {item.linkSets &&
            (expand || expandAll) &&
            viewMode !== 'mini-list' &&
            item.linkSets.map((linkSet) => (
              <LinkSetContainer key={linkSet.key}>
                <LinkSet data={linkSet} />
              </LinkSetContainer>
            ))}
        </Content>
      </RootContainer>
    </Root>
  )
}

const listItemFragment = graphql`
  fragment ListPageItem_listItem on ListItem {
    slug
    title
    isArchived
    image {
      ...ItemImage_data
    }
    path
    statuses {
      key
      customsAgent
      value {
        ...SemanticString_data
      }
    }
    stages {
      key
      status
      label
      value
    }
    badges {
      key
      ...Badge_data
    }
    actions {
      key
      ...Action_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    linkSets {
      key
      ...LinkSet_data
    }
    stickyNoteContent
  }
`

export default ListPageItem
