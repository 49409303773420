import { graphql } from 'babel-plugin-relay/macro'
import React, { useContext } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import FormActionBar from '../../components/Form/FormActionBar'
import NiceFormik from '../../components/Form/NiceFormik'
import ContactForm from '../../forms/Contact/DriverContactForm'
import useNiceMutation from '../../mutations/useNiceMutation'
import { DriverContactEditFormQuery } from '../../__generated__/DriverContactEditFormQuery.graphql'
import { DriverContactEditMutation } from '../../__generated__/DriverContactEditMutation.graphql'
import FormContainer from '../../forms/FormContainer'
import SessionContext from '../../SessionContext'

interface Props {
  slug: string
  onClose: () => void
}

const DriverContactEdit: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<DriverContactEditFormQuery>(query, { slug: slug })
  const { user, reloadSession } = useContext(SessionContext)
  const fields = data.infoUpdateDriverContactForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit] = useNiceMutation<DriverContactEditMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={data.infoUpdateDriverContactForm.initialValue || { roles: [], address: '', businessPhone: '', place: '' }}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (_res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
                if (slug === user?.username) {
                  reloadSession()
                }
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm, values }) => (
          <FormContainer>
            <ContactForm fieldMaster={fieldMaster} mode='edit' />
            <FormActionBar title={`Editing ${(values as any).firstName}`} onCancel={() => onClose()} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query DriverContactEditFormQuery($slug: String!) {
    infoUpdateDriverContactForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        imageSlug
        contactType
        format
        salutation
        firstName
        lastName
        email
        secondaryEmails
        businessPhone
        position
        country
        state
        place
        postcode
        address
        language
        eori
        vat
      }
    }
  }
`

const mutation = graphql`
  mutation DriverContactEditMutation($input: infoUpdateDriverContactInput!) {
    infoUpdateDriverContact(input: $input) {
      contact {
        slug
      }
    }
  }
`

DriverContactEdit.Skeleton = () => (
  <>
    <ContactForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default DriverContactEdit
