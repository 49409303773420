import { IconButton, MenuItem, TextField, Typography, Link, Tooltip } from '@material-ui/core'
import { TableChart, ViewList } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState, useEffect, unstable_useTransition, useContext } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { isSmall, media } from '../../theme'
import { ViewMode } from '../../viewTypes'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { useLocation, useNavigate } from 'react-router'
import moment from 'moment'
import axios from 'axios'
import fileDownload from 'js-file-download'
import LoadingDots from '../../components/LoadingDots'
import { useSnackbar } from 'notistack'
import { getToken } from '../../api/server'
import { ExternalItemListHeaderQuery, ExternalItemListHeaderQueryVariables } from '../../__generated__/ExternalItemListHeaderQuery.graphql'
import Button from '../../components/Button'
import { parse, stringify } from 'qs'
import DateRangePickerFilter from '../../components/DateRangePickerFilter'
import WorkspacedLink from '../../components/WorkspacedLink'
import { Add } from '@material-ui/icons'
import AddTicketDialog from '../../components/AddTicketDialog'
import SessionContext from '../../SessionContext'
interface Props {
  variables: ExternalItemListHeaderQueryVariables
  viewMode: ViewMode
  setViewMode?: (mode: ViewMode) => void
  roles: []
}

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;

  ${media.mobile`
    align-items: flex-start;
    flex-direction: column;
  `}
`

const ResultText = styled(Typography)`
  flex-grow: 2;

  ${media.mobile`
    margin-bottom: 8px;
  `}
`

const SortContainer = styled.div`
  display: flex;
  align-items: center;
`

const Sort = styled(TextField)`
  min-width: 200px;
  /* margin-right: 16px; */
`

const DateRangeStyle = styled.div`
  display: flex;
  float: right;
  margin: 8px 20px 5px;
`

const archivableEntityTypes = ['shipments', 'containers', 'item']

const ExternalItemListHeader: React.FC<Props> = ({ variables, viewMode, setViewMode, roles = [] }) => {
  const data = useLazyLoadQuery<ExternalItemListHeaderQuery>(query, variables)
  // @ts-ignore
  const { sortKeys, totalCount, downloadToken } = useFragment(fragment, data.externalItemList)
  const wsNavigate = useWorkspaceNavigate()
  const navigate = useNavigate()
  const wsLocation = useLocation()
  const workSpace = useLocation().pathname.split('/')[1]
  const [loading, setLoading] = useState<boolean>(false)
  const url = `${process.env.REACT_APP_API_URL}/shipments/download?download_token=${downloadToken}&workspace=${workSpace}`
  const { enqueueSnackbar } = useSnackbar()
  const queryVariables = parse(wsLocation.search, { ignoreQueryPrefix: true })
  const [startTransition] = unstable_useTransition()
  const [voyageDateRange, setVoyageDateRange] = React.useState([] as any)
  const [dischargeDateRange, setDischargeDateRange] = React.useState([] as any)
  const [filterType, setFilterType] = React.useState(null)
  const [addTicketOpen, setTicketOpen] = useState(false)
  const { user } = useContext(SessionContext)

  const handleSortToggle = (key: string) => wsNavigate.relative({ sortKey: key })
  const toggleArchivedVisibility = () =>
    wsNavigate.relative({
      includeArchived: variables.includeArchived ? 'false' : 'true',
    })

  const authorizeDownload = () => {
    setLoading(true)
    const filename = `shipments-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    const token = getToken()
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
        setLoading(false)
      })
      .catch((error) => {
        error.response.data.text().then(function (text: string) {
          enqueueSnackbar(JSON.parse(text as string).errors, { variant: 'error' })
        })
        setLoading(false)
      })
  }

  useEffect(() => {
    const { voyageEtaStart, voyageEtaEnd, dischargeDateStart, dischargeDateEnd } = queryVariables

    if (voyageEtaStart && voyageEtaEnd) {
      const startDate = moment(voyageEtaStart as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(voyageEtaEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setVoyageDateRange([startDate, endDate])
      }
    }

    if (dischargeDateStart && dischargeDateEnd) {
      const startDate = moment(dischargeDateEnd as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(dischargeDateEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setDischargeDateRange([startDate, endDate])
      }
    }
  }, [])

  useEffect(() => {
    dateFilter()
  }, [voyageDateRange, dischargeDateRange])

  const dateFilter = () => {
    const tempVars = Object.assign({}, queryVariables)
    const startKey = filterType === 'voyage' ? 'voyageEtaStart' : `${filterType}DateStart`
    const endKey = filterType === 'voyage' ? 'voyageEtaEnd' : `${filterType}DateEnd`
    let dateRange
    if (filterType == 'voyage') {
      dateRange = voyageDateRange
    } else if (filterType == 'discharge') {
      dateRange = dischargeDateRange
    }

    if (dateRange && Array.isArray(dateRange) && dateRange.length == 2) {
      const validateStart = moment(dateRange[0], 'YYYY-MM-DD', true).isValid()
      const validateEnd = moment(dateRange[1], 'YYYY-MM-DD', true).isValid()

      if (validateStart && validateEnd) {
        tempVars[startKey] = moment(dateRange[0]).format('YYYY-MM-DD')
        tempVars[endKey] = moment(dateRange[1]).format('YYYY-MM-DD')
      } else {
        delete tempVars[startKey]
        delete tempVars[endKey]
      }
    } else {
      delete tempVars[startKey]
      delete tempVars[endKey]
    }
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }

  const checkShipperRole = () => {
    return Array.isArray(roles) && roles.some((item) => item.value === 'shipper')
  }

  const handleDateChange = (filterType: any, newDateRange: any) => {
    setFilterType(filterType)
    if (filterType == 'voyage') {
      setVoyageDateRange(newDateRange)
    } else if (filterType == 'discharge') {
      setDischargeDateRange(newDateRange)
    }
  }

  const checkTransporterRole = () => {
    return user?.contact?.displayRoles.filter((role) => role.value == 'transporter').length == 1
  }

  return (
    <Root>
      <ResultText variant='body1'>1-{totalCount}</ResultText>
      <DateRangeStyle className='date-range-filters'>
        {['containers', 'shipments', 'voyages'].includes(variables.type || '') && (
          <DateRangePickerFilter filterType='voyage' handleDateChange={handleDateChange} heading='Voyage ETA' dateRange={voyageDateRange} />
        )}
        {variables.type === 'shipments' && (
          <DateRangePickerFilter
            filterType='discharge'
            handleDateChange={handleDateChange}
            heading='Discharge Date'
            dateRange={dischargeDateRange}
          />
        )}
      </DateRangeStyle>
      {['containers', 'shipments', 'tickets'].includes(variables.type || '') && checkShipperRole() && (
        <Button variant='contained' onClick={() => setTicketOpen(true)}>
          <Add />
        </Button>
      )}
      {['external_contacts'].includes(variables.type || '') && checkTransporterRole() && (
        <Button variant='contained' onClick={() => setTicketOpen(true)}>
          <Add />
        </Button>
      )}
      <AddTicketDialog open={addTicketOpen} onClose={() => setTicketOpen(false)} entityType={variables.type as string} />
      <SortContainer>
        {variables.type && variables.type === 'shipments' && (
          <IconButton color='primary'>
            <Link onClick={authorizeDownload}>{loading ? <LoadingDots variant='dark' /> : <SystemUpdateAltIcon />}</Link>
          </IconButton>
        )}
        {variables.type && archivableEntityTypes.includes(variables.type) && (
          <Button style={{ minWidth: 145 }} variant='outlined' onClick={toggleArchivedVisibility}>
            {variables.includeArchived ? 'Hide Archived' : 'Include Archived'}
          </Button>
        )}
        <Sort
          select
          size='small'
          variant='outlined'
          value={variables.sortKey}
          label='Sort By'
          onChange={(e) => handleSortToggle(e.target.value)}
        >
          {sortKeys.map((option: any) => (
            <MenuItem key={option.key} value={option.key}>
              {option.title}
            </MenuItem>
          ))}
        </Sort>
        {!isSmall() && viewMode !== 'mini-list' && (
          <>
            <IconButton color={viewMode === 'list' ? 'primary' : 'default'} onClick={() => setViewMode && setViewMode('list')}>
              <ViewList />
            </IconButton>
          </>
        )}
        {variables.type && ['shipments'].includes(variables.type) && (
          <>
            <WorkspacedLink to={'/' + variables.type + '/v3'}>
              <Tooltip title={'Table View'} interactive>
                <IconButton>
                  <TableChart />
                </IconButton>
              </Tooltip>
            </WorkspacedLink>
          </>
        )}
      </SortContainer>
    </Root>
  )
}

const fragment = graphql`
  fragment ExternalItemListHeader_data on ExternalItemList {
    sortKeys {
      key
      title
    }
    totalCount
    downloadToken
  }
`

const query = graphql`
  query ExternalItemListHeaderQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $includeArchived: Boolean
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      includeArchived: $includeArchived
    ) {
      ...ExternalItemListHeader_data
    }
  }
`

export default ExternalItemListHeader
