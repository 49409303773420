import { graphql } from 'babel-plugin-relay/macro'
import NiceFormik from './Form/NiceFormik'
import React from 'react'
import { DialogActions, DialogContent } from '@material-ui/core'
import DraftContactForm from './DraftContactForm'
import { Form } from 'formik'
import LoadingDots from './LoadingDots'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { DriverContactAddQuery } from '../__generated__/DriverContactAddQuery.graphql'
import { DriverContactAddMutation } from '../__generated__/DriverContactAddMutation.graphql'
import Button from './Button'
import useNiceMutation from '../mutations/useNiceMutation'
import { useSnackbar } from 'notistack'

interface Props {
  open: boolean
  onClose: () => void
}

const DriverContactAdd: React.FC<Props> = (props) => {
  const data = useLazyLoadQuery<DriverContactAddQuery>(query, {})
  const fields = data.infoAddDriverContactForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [commit, loading] = useNiceMutation<DriverContactAddMutation>(mutation)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmitForm = (values: any) => {
    const errors: any = {}
    if (!values.salutation) {
      errors.salutation = 'salutation is required'
    }
    if (!values.format) {
      errors.format = 'format is required'
    }
    if (!values.firstName) {
      errors.firstName = 'First name is required'
    }
    if (!values.lastName) {
      errors.lastName = 'Last name is required'
    }
    if (!values.address) {
      errors.address = 'address is required'
    }
    if (!values.country) {
      errors.country = 'country is required'
    }

    if (Object.keys(errors).length > 0) {
      enqueueSnackbar(Object.values(errors).join(', '), { variant: 'error' })
      return
    }
    commit({
      variables: {
        input: values as any,
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          props.onClose()
        }
      },
    })
  }

  return (
    <>
      <NiceFormik initialValues={{}} fieldMaster={fieldMaster}>
        {({ values }) => {
          return (
            <Form>
              <DialogContent dividers={true}>
                <DraftContactForm fieldMaster={fieldMaster} mode={'add-driver'} />
              </DialogContent>
              <DialogActions>
                <Button onClick={props.onClose} variant='contained'>
                  Cancel
                </Button>
                <Button onClick={() => handleSubmitForm(values)} variant='contained'>
                  {loading ? <LoadingDots /> : 'Save'}
                </Button>
              </DialogActions>
            </Form>
          )
        }}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query DriverContactAddQuery {
    infoAddDriverContactForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
    }
  }
`
const mutation = graphql`
  mutation DriverContactAddMutation($input: infoAddDriverContactInput!) {
    infoAddDriverContact(input: $input) {
      clientMutationId
    }
  }
`
export default DriverContactAdd
