import { DialogActions, DialogContent, DialogTitle, List, ListItem, TextField } from '@material-ui/core'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import OdysseyDialog from './OdysseyDialog'
import { graphql } from 'babel-plugin-relay/macro'
import { ShareContactQuery } from '../__generated__/ShareContactQuery.graphql'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import styled from 'styled-components'
import Button from './Button'
import { ShareContactMutation } from '../__generated__/ShareContactMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import LoadingDots from './LoadingDots'
import { useSnackbar } from 'notistack'

const ShareContainer = styled.div`
  margin: 20px;
`
interface Props {
  open: boolean
  slug: string
  onClose: () => void
  role: string | null
}

const toCamelCaseAndPlural = (str: string) => {
  const camelCaseStr = str.charAt(0).toUpperCase() + str.slice(1)
  if (camelCaseStr.endsWith('y')) {
    return camelCaseStr.slice(0, -1) + 'ies'
  }
  return camelCaseStr + 's'
}

const ShareContact: React.FC<Props> = (props) => {
  const role = props.role || 'consignee'

  return (
    <>
      <OdysseyDialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{`${toCamelCaseAndPlural(role)}`}</DialogTitle>
        <Suspense fallback={<ShareContactSkeleton {...props} />}>
          <ShareContactContent {...props} role={role}></ShareContactContent>
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

const ShareContactContent: React.FC<Props> = ({ onClose, slug, role = 'consignee' }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const searchQueryRef = useRef(searchQuery)
  const environment = useRelayEnvironment()
  const [options, setOptions] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [selectUser, setSelectUser] = useState<any[]>([])
  const [commit, commitLoading] = useNiceMutation<ShareContactMutation>(mutation)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    fetchQuery<ShareContactQuery>(environment, query, {
      contactSlug: slug,
      searchQuery: searchQuery,
      role: role,
    })
      .toPromise()
      .then((data) => data?.consigneeList || [])
      .then((opts) => {
        setOptions(opts as never[])
        setSelectUser(opts.filter((user) => user.shared))
        setLoading(false)
      })
  }, [searchQuery, environment])

  const onQueryChange = (newQuery: string) => {
    searchQueryRef.current = newQuery
    setTimeout(() => {
      if (searchQueryRef.current === newQuery) {
        setSearchQuery(newQuery)
      }
    }, 300)
  }

  const handleShare = () => {
    commit({
      variables: {
        input: {
          slug: slug,
          contactSlugs: selectUser.map((user) => user.slug).join(','),
        },
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          enqueueSnackbar('contact list updated successfully', { variant: 'success' })
          onClose()
        }
      },
    })
  }

  const buttonText = 'Save'

  return (
    <>
      <ShareContainer>
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          style={{ width: 350 }}
          loading={loading}
          onChange={(event, value) => {
            setSelectUser(value)
          }}
          onInputChange={(event, value) => {
            console.log(value)
          }}
          value={selectUser}
          getOptionDisabled={(option) => option.shared}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={`Selected ${toCamelCaseAndPlural(role || 'consignee')}`}
                placeholder='Favorites'
                onChange={(ev) => {
                  if (ev.target.value !== '' || ev.target.value !== null) {
                    setLoading(true)
                    onQueryChange(ev.target.value)
                  }
                }}
              />
            </>
          )}
        />
      </ShareContainer>
      <DialogActions>
        <Button variant='contained' onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant='outlined' onClick={() => handleShare()}>
          {commitLoading ? <LoadingDots /> : buttonText}
        </Button>
      </DialogActions>
    </>
  )
}

const ShareContactSkeleton: React.FC<Props> = () => (
  <>
    <DialogContent>
      <List>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
      </List>
    </DialogContent>
  </>
)

const query = graphql`
  query ShareContactQuery($contactSlug: String!, $searchQuery: String, $role: String) {
    consigneeList(searchQuery: $searchQuery, role: $role) {
      slug
      name
      shared(contactSlug: $contactSlug)
    }
  }
`
const mutation = graphql`
  mutation ShareContactMutation($input: infoShareContactsInput!) {
    infoShareContacts(input: $input) {
      clientMutationId
    }
  }
`

export default ShareContact
