import React, { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ContactShowHeroCard from '../../components/ContactShowHeroCard'
import Hero from '../../components/Hero'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import HomeContent from './HomeContent'
import NoteContent from '../../components/NoteTab'
import RelatedItemList from '../ItemList/RelatedItemList'
import ContactEdit from './ContactEdit'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import { useRelayEnvironment } from 'react-relay/hooks'
import { createOperationDescriptor, getRequest } from 'relay-runtime'
import { uploadableDocsQuery } from '../../components/File/helpers'
import { ShowPageRenderContext } from '../../viewTypes'
import ContactEmails from './ContactEmails'

const ContactShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <ContactShowWithParams slug={slug} tab={tab} renderContext='page' />
}

interface Params {
  slug: string
  tab: string
  withSkeleton?: boolean
  renderContext?: ShowPageRenderContext
}

export const ContactShowWithParams: React.FC<Params> = ({ slug, tab, withSkeleton = false, renderContext = 'drawer' }) => {
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')
  const environment = useRelayEnvironment()

  useEffect(() => {
    // telling relay to retain these queries in store even when their components are not mounted (until this component unmounts)
    const uploadableDocsQueryDisposable = environment.retain(
      createOperationDescriptor(getRequest(uploadableDocsQuery), { entityType: 'contacts' })
    )

    return () => {
      uploadableDocsQueryDisposable.dispose()
    }
  }, [slug, environment])

  return (
    <EntityShowOrchestrator
      showPage={
        <>
          <Hero backgroundColor='transparent'>
            {withSkeleton ? (
              <Suspense fallback={<ContactShowHeroCard.Skeleton />}>
                <ContactShowHeroCard slug={slug} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
              </Suspense>
            ) : (
              <ContactShowHeroCard slug={slug} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
            )}
          </Hero>
          <NiceTabPanel forTab='home' activeTab={tab}>
            <Suspense fallback={<HomeContent.Skeleton />}>{slug && <HomeContent slug={slug} />}</Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='containers' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList type='containers' contactSlug={slug} />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='shipments' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList type='shipments' contactSlug={slug} />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='notes' activeTab={tab}>
            <Suspense fallback={<NoteContent.Skeleton />}>
              <NoteContent slug={slug} entityType='contacts' />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='emails' activeTab={tab}>
            <Suspense fallback={<HomeContent.Skeleton />}>
              <ContactEmails slug={slug} />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='contacts' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList type='external_contacts' contactSlug={slug} />
            </Suspense>
          </NiceTabPanel>
          <NiceTabPanel forTab='shared-contact' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList type='external_contacts' contactSlug={slug} sharedContactType={'shared-contact'} />
            </Suspense>
          </NiceTabPanel>
          <NiceTabPanel forTab='shared-with' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList type='external_contacts' contactSlug={slug} sharedContactType={'shared-with'} />
            </Suspense>
          </NiceTabPanel>
        </>
      }
      editPage={<ContactEdit slug={slug} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    />
  )
}

export default ContactShow
