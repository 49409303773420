import { Card, Typography, InputAdornment } from '@material-ui/core'
import { ErrorMessage, useFormikContext } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import InputField from '../../components/Form/InputField'
import { FieldT } from '../../components/Form/types'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { media } from '../../theme'
import { Skeleton } from '@material-ui/lab'
import Hero from '../../components/Hero'
import ShareContact from '../../components/ShareContact'
import Button from '../../components/Button'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const HeroContainer = styled(FlexContainer)`
  padding: 16px 0;
  position: relative;
  align-items: flex-start;

  ${media.small`
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & :nth-child(1) { order: 1}
    & :nth-child(2) { order: 3}
    & :nth-child(3) { order: 2}
  `}
`
//`

const HeroDetailsCard = styled(Card).attrs({ variant: 'outlined' })`
  flex-grow: 1;
  padding: 16px;
  margin-left: 16px;

  ${media.small`
    margin-left: 0px;
    margin-top: 8px;
  `}
`

const HeroFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 16px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const DetailsCard = styled(Card).attrs({ variant: 'outlined' })`
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
`

const DetailsCardTitle = styled(Typography)`
  margin-bottom: 16px;
`

const DetailsFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 16px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`
const DetailsLongFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

interface Props {
  fieldMaster: {
    [key: string]: FieldT
  }
  mode: 'add' | 'edit'
}

const parameterized = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-')

const ContactForm: React.FC<Props> & { Skeleton: React.FC } = ({ fieldMaster, mode }) => {
  const { values, setFieldValue } = useFormikContext<any>()
  const { roles, contactType, slug } = values
  const titleFields = ['salutation', 'firstName', 'lastName']
  const keyFields = ['email', 'businessPhone', 'country', 'language']
  const atbFieldMaster = {
    description: fieldMaster?.atbForCustoms?.description,
    enum: fieldMaster?.atbForCustoms?.enum,
    enumTitles: fieldMaster?.atbForCustoms?.enumTitles,
    format: fieldMaster?.atbForCustoms?.format,
    name: fieldMaster?.atbForCustoms?.name,
    required: fieldMaster?.atbForCustoms?.required,
    title: 'ATB For Customs',
    type: fieldMaster?.atbForCustoms?.type,
  }

  const contactFields = [
    'format',
    'company',
    'postcode',
    'place',
    'state',
    'position',
    'businessPhone',
    'mobilePhone',
    'privatePhone',
    'faxPhone',
  ]
  const financeFields = ['eori', 'lexOfficeId', 'vat']
  const vatFields = ['vatWarehousing', 'vatRevenues']
  const [shareTransporter, setShareTransporter] = useState<boolean>(false)
  const [shareDriver, setShareDriver] = useState<boolean>(false)

  useEffect(() => {
    if (mode === 'add') {
      setFieldValue(
        'slug',
        [values.lastName ? parameterized(values.lastName) : null, values.firstName ? parameterized(values.firstName)[0] : null]
          .filter((v) => v !== null)
          .join('-')
      )
    }
  }, [values.lastName, values.firstName, setFieldValue, mode])

  const rolesRef = useRef(values.roles)
  useEffect(() => {
    const currentRolesHasConsignee = values.roles && values.roles.includes('consignee')
    const pastRolesHadConsignee = rolesRef.current && rolesRef.current.includes('consignee')
    if (currentRolesHasConsignee && !pastRolesHadConsignee) {
      setFieldValue('rateCardSlug', 'default')
    }
    if (values.roles && values.roles.includes('customer') && !(rolesRef.current && rolesRef.current.includes('customer'))) {
      setFieldValue('vatWarehousing', true)
    }
    if (values.email === '') {
      setFieldValue('email', null)
    }
    rolesRef.current = values.roles
  }, [values.roles, setFieldValue])
  const driverRole = 'driver'
  const transporterRole = 'transporter'

  return (
    <>
      <ShareContact open={shareDriver} onClose={() => setShareDriver(false)} slug={slug} role={driverRole}></ShareContact>
      <ShareContact open={shareTransporter} onClose={() => setShareTransporter(false)} slug={slug} role={transporterRole}></ShareContact>
      <Hero backgroundColor='transparent'>
        <HeroContainer>
          {/* <ImageUpload /> */}
          <InputField of='imageSlug' field={fieldMaster.imageSlug} />
          <HeroDetailsCard>
            <HeroFieldsContainer>
              {titleFields.map((keyField) => (
                <InputField key={keyField} of={keyField} field={fieldMaster[keyField]} />
              ))}
            </HeroFieldsContainer>
            <HeroFieldsContainer>
              <InputField of={'slug'} field={fieldMaster['slug']} disabled={mode === 'edit'} />
              {keyFields.map((keyField) => (
                <InputField key={keyField} of={keyField} field={fieldMaster[keyField]} />
              ))}
            </HeroFieldsContainer>
            <InputField of='roles' field={fieldMaster['roles']} />
            <ErrorMessage name='roles' />
          </HeroDetailsCard>
        </HeroContainer>
      </Hero>

      <ResponsiveGrid
        type='two-column-layout'
        left={[
          <DetailsCard key='contact-details'>
            <DetailsCardTitle variant='h6'>Contact Details</DetailsCardTitle>
            <DetailsFieldsContainer>
              {contactFields.slice(0, 2).map((keyField) => (
                <InputField key={keyField} of={keyField} field={fieldMaster[keyField]} />
              ))}
            </DetailsFieldsContainer>
            <DetailsLongFieldsContainer>
              <InputField of='address' field={fieldMaster['address']} variant='large' />
            </DetailsLongFieldsContainer>
            <DetailsFieldsContainer>
              {contactFields.slice(2, contactFields.length).map((keyField) => (
                <InputField key={keyField} of={keyField} field={fieldMaster[keyField]} />
              ))}
              {contactType === 'internal' && (
                <InputField key='zollContactNumber' of='zollContactNumber' field={fieldMaster['zollContactNumber']} />
              )}
              {contactType === 'internal' && <InputField key='zollPhone' of='zollPhone' field={fieldMaster['zollPhone']} />}
            </DetailsFieldsContainer>
            <DetailsLongFieldsContainer>
              <InputField of='website' field={fieldMaster['website']} variant='large' />
              <InputField of='secondaryEmails' field={fieldMaster['secondaryEmails']} variant='large' />
              <Typography variant='caption'>Press enter to add additional email-ids</Typography>
            </DetailsLongFieldsContainer>
            <InputField key={'blocked'} of={'blocked'} field={fieldMaster['blocked']} />
          </DetailsCard>,
        ]}
        right={[
          <>
            {contactType === 'external' && (
              <>
                <DetailsCard key='finance-details'>
                  <DetailsCardTitle variant='h6'>Finance Details</DetailsCardTitle>
                  <DetailsFieldsContainer>
                    {financeFields.map((keyField) => (
                      <InputField key={keyField} of={keyField} field={fieldMaster[keyField]} />
                    ))}
                    {roles?.includes('customer') &&
                      vatFields.map((keyField) => <InputField key={keyField} of={keyField} field={fieldMaster[keyField]} />)}
                  </DetailsFieldsContainer>
                  {roles?.includes('warehouse') ? (
                    <DetailsFieldsContainer>
                      <InputField of={'customsOffice'} field={fieldMaster['customsOffice']} />
                      <InputField of={'freeWarehousingDays'} field={fieldMaster['freeWarehousingDays']} />
                      <InputField
                        of={'costWarehousingPerDay'}
                        field={fieldMaster['costWarehousingPerDay']}
                        InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
                      />
                      <InputField
                        of={'costShipmentDischarge'}
                        field={fieldMaster['costShipmentDischarge']}
                        InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
                      />
                      <InputField
                        of={'expenses'}
                        field={fieldMaster['expenses']}
                        InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
                      />
                    </DetailsFieldsContainer>
                  ) : (
                    <></>
                  )}
                  {roles?.includes('consignee') ? <InputField of='rateCardSlug' field={fieldMaster['rateCardSlug']} /> : <></>}
                </DetailsCard>
                {roles?.includes('warehouse') ? (
                  <DetailsCard key='miscellaneous-settings'>
                    <DetailsCardTitle variant='h6'>Miscellaneous Settings</DetailsCardTitle>
                    <DetailsFieldsContainer>
                      <InputField of={'syncWithEmory'} field={fieldMaster['syncWithEmory']} />
                      <InputField of={'atbForCustoms'} field={atbFieldMaster} />
                    </DetailsFieldsContainer>
                  </DetailsCard>
                ) : (
                  <></>
                )}
                {roles?.includes('transporter') ? (
                  <DetailsCard key='transporter'>
                    <DetailsCardTitle variant='h6'>Drivers</DetailsCardTitle>
                    <DetailsFieldsContainer>
                      <Button variant='outlined' onClick={() => setShareDriver(true)}>
                        Add Drivers
                      </Button>
                    </DetailsFieldsContainer>
                  </DetailsCard>
                ) : (
                  <></>
                )}
                {roles?.includes('driver') ? (
                  <DetailsCard key='driver'>
                    <DetailsCardTitle variant='h6'>Transporters</DetailsCardTitle>
                    <DetailsFieldsContainer>
                      <Button variant='outlined' onClick={() => setShareTransporter(true)}>
                        Add Transporters
                      </Button>
                    </DetailsFieldsContainer>
                  </DetailsCard>
                ) : (
                  <></>
                )}
              </>
            )}
          </>,
        ]}
      />
    </>
  )
}

const FieldSkeleton = styled(Skeleton).attrs({ variant: 'rect', height: '50px' })``

ContactForm.Skeleton = () => (
  <>
    <Hero backgroundColor='transparent'>
      <HeroContainer>
        <Skeleton variant='circle' height='184px' width='184px' />
        <HeroDetailsCard>
          <HeroFieldsContainer>
            <FieldSkeleton />
            <FieldSkeleton />
            <FieldSkeleton />
          </HeroFieldsContainer>
          <HeroFieldsContainer>
            <FieldSkeleton />
            <FieldSkeleton />
            <FieldSkeleton />
          </HeroFieldsContainer>
          <HeroFieldsContainer>
            <FieldSkeleton />
          </HeroFieldsContainer>
        </HeroDetailsCard>
      </HeroContainer>
    </Hero>

    <ResponsiveGrid
      type='two-column-layout'
      left={[
        <DetailsCard key='contact-details'>
          <Skeleton>
            <DetailsCardTitle variant='h6'>....................</DetailsCardTitle>
          </Skeleton>
          <DetailsFieldsContainer>
            <FieldSkeleton />
            <FieldSkeleton />
            <FieldSkeleton />
          </DetailsFieldsContainer>
        </DetailsCard>,
      ]}
      right={[
        <DetailsCard key='finance-details'>
          <Skeleton>
            <DetailsCardTitle variant='h6'>.............................</DetailsCardTitle>
          </Skeleton>
          <DetailsFieldsContainer>
            <FieldSkeleton />
            <FieldSkeleton />
            <FieldSkeleton />
          </DetailsFieldsContainer>
        </DetailsCard>,
      ]}
    />
  </>
)

export default ContactForm
